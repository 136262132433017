import React from "react";
import { Typography, useTheme, Theme, Button, Grid, TextField, Box, Container } from "@material-ui/core";
import styled from "@emotion/styled";
import { RoundPaper } from "./styledComponents";
import ContactForm from "./contact-form";

const ContrastText = styled(Typography, {
    shouldForwardProp: prop => prop !== "textColor"
})((props: { textColor: string }) => `
    color: ${props.textColor};
`);

const PaddedGridTop = styled(Grid)`
padding-top: 16px;
`;

const PackageCustom: React.FC = () => {
    const theme = useTheme<Theme>();

    return (
        <>
            <ContrastText textColor={theme.palette.primary.contrastText} variant="h3" align="center" >Personalize Your Package</ContrastText>
            <ContrastText textColor={theme.palette.primary.contrastText} variant="subtitle1" align="center">Contact Us To Get Started</ContrastText>
            <PaddedGridTop container justify="center" alignItems="center">
                <Grid item xs={11} sm={6}>
                    <RoundPaper>
                        <Box py={4}>
                            <ContactForm cta="Submit" />
                        </Box>
                    </RoundPaper>
                </Grid>
            </PaddedGridTop>
        </>
    );
}

export default PackageCustom;